<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="promotionTypeID != null">Edit Promotion Type</b>
      <b v-else>Create Promotion Type</b>
    </span>
    <div class="vx-row mb-6" v-if="promotionTypeID != null">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="Code" v-model="data.code" />
      </div>
    </div>
    <div class="vx-row mb-6" v-else>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          name="Code"
          label="Code"
          v-model="data.code"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Code')">{{
          errors.first("Code")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Sequence"
          name="Sequence"
          type="number"
          v-validate="'required'"
          v-model="data.sequence"
        />
        <span class="text-danger text-sm" v-show="errors.has('Sequence')">{{
          errors.first("Sequence")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    promotionTypeID: {
      type: Number,
    },
    baseUrl: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        // baseUrl: "/api/v2/master/promotion-type",
        data: {
          code: "",
          name: "",
          sequence: 0,
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.promotionTypeID) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        id: this.promotionTypeID,
        code: this.data.code,
        name: this.data.name,
        sequence: parseInt(this.data.sequence),
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "successfully added data",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.promotionTypeID) {
        this.$http
          .get(this.baseUrl + "/" + this.promotionTypeID)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.data.code = resp.data.code;
              this.data.name = resp.data.name;
              this.data.sequence = resp.data.sequence;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
  },
  mounted() {
    if (this.promotionTypeID) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    promotionTypeID(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
