<template>
  <vx-card title="Promotion">
    <vs-tabs>
      <vs-tab label="Promotion Type">
        <promotion-type></promotion-type>
      </vs-tab>
      <vs-tab label="Promotion Variable">
        <div class="tab-text">
          <promotion-variable></promotion-variable>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import PromotionType from "./PromotionType.vue";
import PromotionVariable from "./PromotionVariable.vue";
export default {
  components: {
    PromotionType,
    PromotionVariable,
  },
};
</script>
