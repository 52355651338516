<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="promotionVariableID != null">Edit Promotion variable</b>
      <b v-else>Create Promotion variable</b>
    </span>
    <div class="vx-row mb-6" v-if="promotionVariableID != null">
      <div class="vx-col w-full">
        <vs-input disabled class="w-full" label="Code" v-model="data.code" />
      </div>
    </div>
    <div class="vx-row mb-6" v-else>
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          name="Code"
          label="Code"
          v-model="data.code"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('Code')">{{
          errors.first("Code")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6 mt-3">
      <div class="vx-col w-full">
        <label class="vs-input--label">Company</label>
        <multiselect
          class="selectExample"
          v-model="selectCompany.model"
          :options="selectCompany.options"
          :multiple="false"
          :allow-empty="false"
          :loading="selectCompany.loading"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          name="Company"
          label="Company"
          :disabled="false"
          :custom-label="customLableSelect"
        >
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('Company')">{{
          errors.first("Company")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Name"
          v-validate="'required'"
          name="Name"
          v-model="data.name"
        />
        <span class="text-danger text-sm" v-show="errors.has('Name')">{{
          errors.first("Name")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Group"
          name="Group"
          v-validate="'required'"
          v-model="data.group"
        />
        <span class="text-danger text-sm" v-show="errors.has('Group')">{{
          errors.first("Group")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Priority"
          name="Priority"
          type="number"
          v-validate="'required'"
          v-model="data.priority"
        />
        <span class="text-danger text-sm" v-show="errors.has('Priority')">{{
          errors.first("Priority")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    promotionVariableID: {
      type: Number,
    },
    baseUrl: {
      type: String,
    },
    dataEdit: {
      type: Object,
    },
  },

  data() {
    return {
      data: {
        code: "",
        name: "",
        company_id: 0,
        group: "",
        priority: 0,
      },
      company_iloop: 0,
      max_loop: 20,
      selectCompany: {
        model: {
          id: 0,
          code: "",
          name: "",
        },
        loading: false,
        options: [],
      },
    };
  },
  methods: {
    initialState() {
      this.data = {
        code: "",
        name: "",
        company_id: 0,
        group: "",
        priority: 0,
      };
      this.company_iloop = 0;
      this.max_loop = 20;
      this.selectCompany.model = {
        id: 0,
        code: "",
        name: "",
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$vs.loading();
          if (this.promotionVariableID) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    paramData() {
      return {
        id: this.promotionVariableID,
        company_id: parseInt(this.selectCompany.model.id),
        code: this.data.code,
        name: this.data.name,
        group: this.data.group,
        priority: parseInt(this.data.priority),
      };
    },
    postData() {
      this.$http
        .post(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "successfully added data",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.baseUrl, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      if (this.promotionVariableID) {
        this.data.company_id = this.dataEdit.company_id;
        this.data.code = this.dataEdit.code;
        this.data.name = this.dataEdit.name;
        this.data.group = this.dataEdit.group;
        this.data.priority = this.dataEdit.priority;
        this.setCompany();
      }
    },
    getOptionCompany() {
      this.selectCompany.loading = true;
      this.$http
        .get(this.baseUrl + "/option-company")
        .then((resp) => {
          this.selectCompany.loading = false;
          if (resp.code == 200) {
            this.selectCompany.options = resp.data;
            if (this.selectCompany.options.length > 0) {
              this.selectCompany.model = this.selectCompany.options[0];
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.selectCompany.loading = false;
          console.log(error);
        });
    },
    customLableSelect({ code, name }) {
      return `${code} - ${name}`;
    },
    setCompany() {
      console.log("recheck, ", this.selectCompany.loading);
      setTimeout(() => {
        if (this.selectCompany.loading) {
          if (this.company_iloop >= this.max_loop) {
            console.log("iterasi maksimum ", this.max_loop);
            this.company_iloop = 0;
            return;
          }
          this.company_iloop++;
          this.setCompany();
        } else {
          this.company_iloop = 0;
          var selectCompanyFilter = this.selectCompany.options.filter((v) => {
            return v.id == this.dataEdit.company_id;
          });
          console.log(selectCompanyFilter);
          if (selectCompanyFilter[0]) {
            this.selectCompany.model = selectCompanyFilter[0];
          } else {
            this.selectCompany.model = {
              id: this.dataEdit.company_id,
              code: this.dataEdit.company_code,
              name: this.dataEdit.company_name,
            };
            this.selectCompany.options.push(this.selectCompany.model);
          }
        }
      }, 1000);
    },
  },
  mounted() {
    if (this.promotionVariableID) {
      this.getData();
    }
    this.getOptionCompany();
  },
  computed: {},
  watch: {
    promotionVariableID(v) {
      if (v) {
        this.getData();
      } else {
        this.initialState();
      }
    },
  },
};
</script>
